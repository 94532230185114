import { pascalToCamel, fleetoraRewriteProperties } from "../extensions";
import { Vehicle, CachedSnapshot } from "../entities";

const baseUrl = "https://fleetora-service.akac.dev";

export default class FleetoraClient {
  apiKey: string;

  constructor(apiKey: string) {
    this.apiKey = apiKey;
  }

  async getTraffic() {
    let res = await fetch([baseUrl, "/api", "/provoz", "/fresh"].join(""), {
      credentials: "include",
      headers: {
        Authorization: this.apiKey
      }
    });
    if (!res.ok) throw `Failed to fetch Fleetora traffic: ${res.status} => ${await res.text()}`;

    return fleetoraRewriteProperties(pascalToCamel(await res.json())) as Vehicle[];
  }

  async getCachedTraffic() {
    let res = await fetch([baseUrl, "/api", "/provoz", "/cache"].join(""), {
      credentials: "include",
      headers: {
        Authorization: this.apiKey
      }
    });
    if (!res.ok) throw `Failed to fetch Fleetora traffic: ${res.status} => ${await res.text()}`;

    let data = fleetoraRewriteProperties(pascalToCamel(await res.json())) as Vehicle[];

    let snapshot: CachedSnapshot = {
      age: Number(res.headers.get("Age")),
      date: new Date(res.headers.get("Date")),
      isOld: false,
      vehicles: data
    };

    return snapshot;
  }

  async getOldTraffic(date: Date) {
    let res = await fetch([baseUrl, "/api", "/provoz", "/old", `?date=${date.toISOString()}`].join(""), {
      credentials: "include",
      headers: {
        Authorization: this.apiKey
      }
    });

    if (!res.ok) throw `Failed to fetch Fleetora traffic: ${res.status} => ${await res.text()}`;

    let data = fleetoraRewriteProperties(pascalToCamel(await res.json())) as Vehicle[];

    let snapshot: CachedSnapshot = {
      age: Number(res.headers.get("Age")),
      date: new Date(res.headers.get("Date")),
      isOld: true,
      vehicles: data
    };

    return snapshot;
  }
}
