import * as Dom from "./dom";
import * as Data from "./data";
import * as Display from "./display";
import * as Events from "./events";
import { TractionType, Model, ListPair } from "./entities";
import { tractionToHuman, tractionToSA, ageToHuman } from "./extensions";

export let darkenedTag: HTMLDivElement;
export let vehiclesPage: HTMLDivElement;
export let filtersEnabled: boolean;

export function addVehiclesPage(acPercentage: number, averageDelay: number) {
  vehiclesPage = (Dom.templates.vehiclesPage.content.cloneNode(true) as Element).firstElementChild as HTMLDivElement;

  (vehiclesPage.querySelector(".reset-filters") as HTMLButtonElement).onclick = async () => await Events.resetFiltersClicked();
  Array.from(vehiclesPage.querySelectorAll(".filter input") as NodeListOf<HTMLInputElement>).forEach(async (input) => {
    input.oninput = async () => await Events.filterInputsChanged();
  });

  vehiclesPage.querySelector<HTMLElement>(".ac-percentage").textContent = acPercentage.toString();
  vehiclesPage.querySelector<HTMLElement>(".average-delay").textContent = averageDelay.toString();
  if (Data.cachedSnapshot) {
    let parts = ageToHuman(Data.cachedSnapshot.age);
    vehiclesPage.querySelector<HTMLElement>(".data-age").textContent = (parts[0] as number).toString();
    vehiclesPage.querySelector<HTMLElement>(".data-age-unit").textContent = parts[1] as string;

    Display.addDataAge();
  }

  Dom.content.append(vehiclesPage);
}

export function resetDarkenedTag() {
  darkenedTag = null;
}

export function addError(message: string) {
  Dom.dialogs.error.message.innerHTML = message;
  Dom.dialogs.error.self.showModal();
}

export function removeError() {
  Dom.dialogs.error.self.close();
}

export function addVehicleView(line: string, heading: string, seznamAutobusu: string, delay: number, from: string, to: string, lastStop: string, nextStop: string) {
  Dom.dialogs.vehicleView.tag.textContent = line;
  Dom.dialogs.vehicleView.heading.textContent = heading;
  Dom.dialogs.vehicleView.seznamAutobusu.href = seznamAutobusu;
  if (delay == 0) {
    Dom.dialogs.vehicleView.delay.innerHTML = `<span class="safety">Spoj jede včas</span>`;
  } else {
    Dom.dialogs.vehicleView.delay.innerHTML = `+ <span class="danger"><b>${delay}</b> min.</span>`;
  }
  Dom.dialogs.vehicleView.path.textContent = `${from} > ${to}`;
  Dom.dialogs.vehicleView.lastStop.textContent = lastStop;
  Dom.dialogs.vehicleView.nextStop.textContent = nextStop;
  Dom.dialogs.vehicleView.self.showModal();
  Dom.dialogs.vehicleView.seznamAutobusu.blur();
}

export function removeVehicleView() {
  Dom.dialogs.vehicleView.self.close();
}

export function addTraction(type: TractionType, models: Model[]) {
  let vehicles = Data.vehicles.filter((x) => x.tractionType == type);

  console.info(`Adding traction for ${type} with ${models.length} models and ${vehicles.length} vehicles`);

  let tractionClone = (Dom.templates.traction.content.cloneNode(true) as Element).firstElementChild as HTMLDivElement;

  (tractionClone.querySelector(".icon") as HTMLImageElement).src = `/assets/${TractionType[type].toString().toLowerCase()}.svg`;
  (tractionClone.querySelector(".title") as HTMLHeadingElement).textContent = `${tractionToHuman(type)} (${vehicles.length})`;
  let modelsDiv = tractionClone.querySelector(".models") as HTMLDivElement;

  let results: ListPair[] = [];

  for (let model of models) {
    let vehiclesOfModel = vehicles.filter((x) => model.registrationNumbers.includes(x.registrationNumber));
    if (vehiclesOfModel.length == 0) continue;

    vehiclesOfModel = vehiclesOfModel.sort((a, b) => {
      return a.registrationNumber - b.registrationNumber;
    });

    results.push({
      model,
      vehicles: vehiclesOfModel
    });
  }

  results = results.sort((a, b) => {
    return b.vehicles.length - a.vehicles.length;
  });

  let unassigned = vehicles.filter((original) => !results.some((result) => result.vehicles.some((vehicle) => vehicle.registrationNumber == original.registrationNumber)));
  if (unassigned && unassigned.length > 0) {
    console.info(`Unassigned for traction type ${type}`, unassigned);
  }

  let unusual = vehicles.filter((original) => {
    let vehicleType = Number(original.vehicleId.substring(0, 1)) as TractionType;
    let tractionType = original.line.tractionType;

    return vehicleType != tractionType;
  });

  if (unusual && unusual.length > 0) {
    console.info(`Unusual assign for traction type ${type}`, unusual);
  }

  for (let pair of results) {
    console.info(`Model ${pair.model.name} has ${pair.vehicles.length} vehicles on track`);

    let modelClone = (Dom.templates.model.content.cloneNode(true) as Element).firstElementChild as HTMLDivElement;

    (modelClone.querySelector(".name") as HTMLHeadingElement).textContent = `${pair.model.name} (${pair.vehicles.length})`;
    let vehicleTags = modelClone.querySelector(".vehicle-tags") as HTMLDivElement;

    for (let vehicle of pair.vehicles) {
      let tractionId = Number(vehicle.vehicleId[0]);
      let vehicleTagClone = (Dom.templates.vehicleTag.content.cloneNode(true) as Element).firstElementChild as HTMLDivElement;

      vehicleTagClone.dataset.line = vehicle.line.name;
      vehicleTagClone.dataset.registrationNumber = vehicle.registrationNumber.toString();
      vehicleTagClone.textContent = vehicle.registrationNumber.toString();

      let painting = Data.paintings[vehicle.vehicleId];
      if (painting) {
        vehicleTagClone.classList.add("painting", painting.id);
      }

      vehicleTagClone.onclick = async () => {
        Display.darkenTag(vehicleTagClone);
        darkenedTag = vehicleTagClone;

        Display.addVehicleView(
          vehicle.line.name,
          pair.model.name,
          `https://seznam-autobusu.cz/seznam?evc=${vehicle.registrationNumber}&tractionId=${tractionToSA(tractionId)}&iddopravce=71`,
          vehicle.delayMinutes,
          vehicle.startName,
          vehicle.destinationName,
          vehicle.lastStopName,
          vehicle.nextStopName
        );
      };

      vehicleTags.append(vehicleTagClone);
    }

    modelsDiv.append(modelClone);
  }

  vehiclesPage.querySelector(".vehicles-view").append(tractionClone);
}

export function setOnTrack(value: number) {
  Dom.onTrack.textContent = ` (${value})`;
}

export function setFilterResults(value: number) {
  vehiclesPage.querySelector(".filter-results").textContent = ` (${value})`;
}

export function deleteFilterResults() {
  vehiclesPage.querySelector(".filter-results").textContent = "";
}

export function darkenTag(element: HTMLDivElement) {
  element.classList.add("darkened");
}

export function lightenTag(element: HTMLDivElement) {
  element.classList.remove("darkened");
}

export function clearFilters() {
  if (filtersEnabled) {
    clearFilterInputs();
    deleteFilterResults();
    resetFilters();

    filtersEnabled = false;
  } else if (!filtersEnabled && Data.cachedSnapshot.isOld) {
    disableOldPreview();
  }
}

export function clearFilterInputs() {
  let inputs = vehiclesPage.querySelectorAll(".filter input") as NodeListOf<HTMLInputElement>;
  for (let input of inputs) input.value = "";
}

export function applyFilters() {
  filtersEnabled = true;

  let line = (vehiclesPage.querySelector("input[name='line-filter']") as HTMLInputElement).value;
  if (line == "") return;

  resetFilters();

  let lines = [line];
  if (line == "1") lines.push("1A", "1X", "1/2");
  else if (line == "2") lines.push("2A", "2X");
  else if (line == "4") lines.push("4A", "4X");

  let tags = Array.from(vehiclesPage.querySelectorAll(".vehicle-tags .tag") as NodeListOf<HTMLSpanElement>);
  let tagsOfInterest = tags.filter((tag) => {
    let datasetLine = tag.dataset.line;

    return !lines.includes(datasetLine);
  });

  dimVehicles(tagsOfInterest);
  setFilterResults(tags.length - tagsOfInterest.length);
}

export function resetFilters() {
  Array.from(vehiclesPage.querySelectorAll(".vehicle-tags .tag.dim") as NodeListOf<HTMLSpanElement>).forEach((tag) => {
    tag.classList.remove("dim");
  });
}

export function dimVehicles(tags: HTMLElement[]) {
  for (let tag of tags) tag.classList.add("dim");
}

export function disableOldPreview() {
  let url = new URL(window.location.href);
  url.search = "";

  window.location.href = url.toString();
}

export function addLoading() {
  Dom.loading.classList.remove("invisible");
}

export function removeLoading() {
  Dom.loading.classList.add("invisible");
}

export function setLoadingMessage(msg: string) {
  Dom.loading.textContent = msg;
}

export function addDisclaimer() {
  Dom.disclaimer.classList.remove("invisible");
}

export function removeDisclaimer() {
  Dom.disclaimer.classList.add("invisible");
}

export function addDataAge() {
  vehiclesPage.querySelector(".data-age-container").classList.remove("invisible");
}

export function removeDataAge() {
  vehiclesPage.querySelector(".data-age-container").classList.add("invisible");
}
