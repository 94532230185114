export enum TractionType {
  Tram = 1,
  Trolleybus = 2,
  Bus = 3
}

export interface Carrier {
  [key: string]: Model[];
}

export interface Paintings {
  [key: string]: Painting;
}

export interface Painting {
  id: string;
  name: string;
}

export interface Model {
  name: string;
  registrationNumbers: number[];
}

export interface CachedSnapshot {
  age: number;
  date: Date;
  isOld: boolean;
  vehicles: Vehicle[];
}

export interface Vehicle {
  vehicleId: string;
  registrationNumber: number;
  tractionType: TractionType;
  line: LineId;
  connectionId: number;
  delayMinutes: number;
  lastStopIndex: number;
  lastStopName: string;
  nextStopIndex: number;
  nextStopName: string;
  startName: string;
  destinationName: string;
  latitude: number;
  longitude: number;
  azimuth?: number;
  timestamp: string;
  isAirConditioned: boolean;
  quickMessageIds: number[];
}

export interface LineId {
  name: string;
  number: number;
  tractionType: TractionType;
  isBarrierFree: boolean;
  isNightConnection: boolean;
}

export interface ListPair {
  model: Model;
  vehicles: Vehicle[];
}
