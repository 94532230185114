import * as Dom from "./dom";
import * as Display from "./display";

Dom.dialogs.error.close.onclick = async () => {
  Display.removeError();
};

Dom.dialogs.vehicleView.close.onclick = async () => {
  Display.removeVehicleView();
};

Dom.dialogs.vehicleView.self.onclose = async () => {
  if (Display.darkenedTag) Display.lightenTag(Display.darkenedTag);
  Display.resetDarkenedTag();
};

export async function resetFiltersClicked() {
  Display.clearFilters();
}

export async function filterInputsChanged() {
  Display.applyFilters();
}
