export const content = document.querySelector(".content");
export const templates = {
  vehiclesPage: document.querySelector<HTMLTemplateElement>("template[name='vehicles-page']"),
  traction: document.querySelector<HTMLTemplateElement>("template[name='traction']"),
  model: document.querySelector<HTMLTemplateElement>("template[name='model']"),
  vehicleTag: document.querySelector<HTMLTemplateElement>("template[name='vehicle-tag']")
};
export const dialogs = {
  error: {
    self: document.querySelector<HTMLDialogElement>("dialog[name='error']"),
    close: document.querySelector<HTMLDivElement>("dialog[name='error'] .close"),
    message: document.querySelector<HTMLParagraphElement>("dialog[name='error'] .message")
  },
  vehicleView: {
    self: document.querySelector<HTMLDialogElement>("dialog[name='vehicle-view']"),
    close: document.querySelector<HTMLDivElement>("dialog[name='vehicle-view'] .close"),
    tag: document.querySelector<HTMLSpanElement>("dialog[name='vehicle-view'] .tag"),
    heading: document.querySelector<HTMLHeadingElement>("dialog[name='vehicle-view'] h2"),
    seznamAutobusu: document.querySelector<HTMLAnchorElement>("dialog[name='vehicle-view'] a.seznam-autobusu"),
    delay: document.querySelector<HTMLLabelElement>("dialog[name='vehicle-view'] .delay label"),
    path: document.querySelector<HTMLLabelElement>("dialog[name='vehicle-view'] .path label"),
    lastStop: document.querySelector<HTMLLabelElement>("dialog[name='vehicle-view'] .last-stop label"),
    nextStop: document.querySelector<HTMLLabelElement>("dialog[name='vehicle-view'] .next-stop label")
  }
};
export const onTrack = document.querySelector(".on-track") as HTMLSpanElement;
export const loading = document.querySelector(".loading") as HTMLParagraphElement;
export const disclaimer = document.querySelector(".disclaimer") as HTMLParagraphElement;
