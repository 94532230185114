import { Carrier, Paintings } from "../entities";

const baseUrl = "https://pmdp-cdn.pages.dev";

export default class CdnClient {
  async getCarrier() {
    let res = await fetch([baseUrl, "/carrier.json"].join(""));
    if (!res.ok) throw `Failed to fetch carrier: ${res.status} => ${await res.text()}`;

    return (await res.json()) as Carrier;
  }

  async getHistoricalCarrier() {
    let res = await fetch([baseUrl, "/historical.json"].join(""));
    if (!res.ok) throw `Failed to fetch carrier: ${res.status} => ${await res.text()}`;

    return (await res.json()) as Carrier;
  }

  async getPaintings() {
    let res = await fetch([baseUrl, "/paintings.json"].join(""));
    if (!res.ok) throw `Failed to fetch carrier: ${res.status} => ${await res.text()}`;

    return (await res.json()) as Paintings;
  }
}
